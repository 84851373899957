import React from 'react';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import {
  LocationContext,
  Pages,
} from '../../../../contexts/Location/LocationContext';

import { classes } from './EmptyList.st.css';

import settingsParams from '../../settingsParams';

export interface IEmptyListProps {
  title: string;
  onLinkClick?(): void;
}

export const EmptyList: React.FC<IEmptyListProps> = (props) => {
  const settings = useSettings();
  const locationCtx = React.useContext(LocationContext);

  return (
    <section className={classes.root}>
      <p className={classes.text}>{props.title}</p>

      <button
        role="link"
        className={classes.cta}
        onClick={() => {
          props.onLinkClick && props.onLinkClick();

          locationCtx.goToPage({
            pageId: Pages.List,
          });
        }}
      >
        {settings.get(settingsParams.emptyChallengesLinkText)}
      </button>
    </section>
  );
};

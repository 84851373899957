import React from 'react';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import settingsParams from '../../settingsParams';

import { classes, st } from './Title.st.css';

export interface ITitle {
  title?: string;
  subtitle?: string;
}

export const Title: React.FC<ITitle> = (props) => {
  const settings = useSettings();

  return (
    <div className={st(classes.root, {})}>
      <h1 className={classes.title}>
        {props.title || settings.get(settingsParams.pageTitleText)}
      </h1>

      <h2 className={classes.subtitle}>
        {props.subtitle || settings.get(settingsParams.pageSubtitleText)}
      </h2>
    </div>
  );
};
